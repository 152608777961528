var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.roomsFree,"label":_vm.$t('lbl.rooms'),"outlined":"","dense":"","multiple":"","hide-details":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.rooms'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.descuento.rooms_id),callback:function ($$v) {_vm.$set(_vm.descuento, "rooms_id", $$v)},expression:"descuento.rooms_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.hotel.descuentos.length > 1 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteDescuentoOfertEBB({ pos: _vm.posHotel, posDescuento: _vm.pos })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,2877476478)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e(),(_vm.pos === 0 && !_vm.$store.state.app.onlyShow)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addDescuentoOfertEBB(_vm.pos)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,1310500432)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])]):_vm._e()],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12","md":"6","offset-md":"1"}},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"2"}},[_c('strong',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('lbl.travel')))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-input mb-0",attrs:{"label":_vm.$t('lbl.from'),"disabled":_vm.$store.state.app.onlyShow,"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateViajeFormatted),callback:function ($$v) {_vm.computedFromDateViajeFormatted=$$v},expression:"computedFromDateViajeFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromViaje),callback:function ($$v) {_vm.menuFromViaje=$$v},expression:"menuFromViaje"}},[_c('v-date-picker',{attrs:{"no-title":"","min":new Date(Date.now()).toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFromViaje = false
              _vm.activeToDateViaje()}},model:{value:(_vm.descuento.fromViaje),callback:function ($$v) {_vm.$set(_vm.descuento, "fromViaje", $$v)},expression:"descuento.fromViaje"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-input",attrs:{"label":_vm.$t('lbl.to'),"disabled":_vm.descuento.fromViaje === null || _vm.$store.state.app.onlyShow,"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedToDateViajeFormatted),callback:function ($$v) {_vm.computedToDateViajeFormatted=$$v},expression:"computedToDateViajeFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuToViaje),callback:function ($$v) {_vm.menuToViaje=$$v},expression:"menuToViaje"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.descuento.fromViaje
                ? _vm.$moment(_vm.descuento.fromViaje)
                  .add(1, 'day')
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
                : ''},on:{"input":function($event){_vm.menuToViaje = false}},model:{value:(_vm.descuento.toViaje),callback:function ($$v) {_vm.$set(_vm.descuento, "toViaje", $$v)},expression:"descuento.toViaje"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"disabled":_vm.$store.state.app.onlyShow,"items":_vm.allPlanes,"label":_vm.$t('lbl.plan'),"outlined":"","dense":"","hide-details":"","item-text":"siglas","item-value":"id"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.plan'))+" ")])],1)]},proxy:true}]),model:{value:(_vm.descuento.plan_id),callback:function ($$v) {_vm.$set(_vm.descuento, "plan_id", $$v)},expression:"descuento.plan_id"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"mt-0",attrs:{"cols":"12","md":"3","offset-md":"2"}},[_c('v-text-field',{staticClass:"my-input",attrs:{"label":_vm.$t('lbl.descuent'),"disabled":_vm.$store.state.app.onlyShow,"outlined":"","dense":"","hide-details":"","type":"number","prefix":_vm.descuento.in_value_descuento ? '$' : '',"suffix":!_vm.descuento.in_value_descuento ? '%' : ''},model:{value:(_vm.descuento.valueDescuento),callback:function ($$v) {_vm.$set(_vm.descuento, "valueDescuento", $$v)},expression:"descuento.valueDescuento"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-switch',{staticClass:"mt-0",attrs:{"disabled":_vm.$store.state.app.onlyShow,"label":_vm.$t('lbl.valor'),"hide-details":""},model:{value:(_vm.descuento.in_value_descuento),callback:function ($$v) {_vm.$set(_vm.descuento, "in_value_descuento", $$v)},expression:"descuento.in_value_descuento"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }