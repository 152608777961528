var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('fragment',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.identificador')))])]),_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.proveedor')))])]),_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.tipo')))])]),_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.dateSale')))])]),_c('th',{staticClass:"text-uppercase"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.state')))])]),(
                _vm.permisos.includes('oferts:edit') ||
                  _vm.permisos.includes('oferts:destroy') ||
                  _vm.permisos.includes('oferts:list') ||
                  _vm.permisos.includes('*')
              )?_c('th',{staticClass:"text-uppercase",attrs:{"width":"150px"}},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.actions')))])]):_vm._e()]),_vm._l((_vm.itemsProduct),function(mark,indM){return _c('tr',{key:indM},[_c('td',[_vm._v(" "+_vm._s(mark.identificador)+" ")]),_c('td',[_vm._v(" "+_vm._s(mark.proveedor)+" ")]),_c('td',[_vm._v(" "+_vm._s(mark.tipo)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(mark.from_sale).format('D-MMM-YY'))+" / "+_vm._s(_vm.$moment(mark.to_sale).format('D-MMM-YY'))),_c('br')]),_c('td',[_vm._v(" "+_vm._s(mark.status)+" ")]),(
                _vm.permisos.includes('oferts:edit') ||
                  _vm.permisos.includes('oferts:destroy') ||
                  _vm.permisos.includes('oferts:list') ||
                  _vm.permisos.includes('*')
              )?_c('td',[(_vm.permisos.includes('oferts:edit') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(_vm.permisos.includes('oferts:list') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.seeItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.see')))])]):_vm._e(),(_vm.permisos.includes('oferts:destroy') || _vm.permisos.includes('*'))?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(mark)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])})],2)],1)]},proxy:true}],null,false,2517150199)}):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","max-width":"380px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('btn.delete')))))]),_c('v-divider'),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('msg.deleteOfertEBB')))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }