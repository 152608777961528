<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <!--<v-autocomplete
          v-model="hotel.hotel_id"
          :items="itemsHotel"
          :search-input.sync="searchHotel"
          hide-details
          :label="$t('lbl.hotel')"
          outlined
          dense
          item-text="name"
          item-value="id"
          @change="setHotel"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.hotel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete>-->

        <v-select
          v-model="hotel.hotel_id"
          :items="hotelsFree"
          :disabled="$store.state.app.onlyShow || !showHotelEBB || indexHotelEBB !== pos"
          :label="$t('lbl.hotel')"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          @change="setHotel()"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.hotel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="ofertEBBDates.hotels.length > 1 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              v-bind="attrs"
              fab
              dark
              x-small
              color="error"
              v-on="on"
              @click="deleteHotelOfertEBB(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="pos === 0 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addHotelOfertEBB()"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>

      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          fab
          icon
          @click="updateShowHotelEBB(pos)"
        >
          <v-icon>
            {{ showHotelEBB && indexHotelEBB === pos ? icons.mdiMenuDown : icons.mdiMenuRight }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="showHotelEBB && indexHotelEBB === pos">
      <v-col
        v-if="showRoomsPrice && ofertEBBDates.modelo === 'precio'"
        cols="12"
        md="12"
      >
        <fragment v-if="!ofertEBBDates.by_suplement_reductions">
          <OfertsHotelPrices
            v-for="(room, indR) in hotel.rooms_by_price"
            :key="indR + 1"
            :room="room"
            :pos="indR"
            :hotel="hotel"
            :edad="edadHotelOnlyAdult"
            :pos-hotel="pos"
            :all-rooms="allRooms"
            :hotel-rooms="roomsId"
            :all-suplement="allSuplement"
            :all-reductions="allReductions"
            :all-planes="allPlanes"
          />
        </fragment>
        <fragment v-else>
          <OfertsHotelPricesBySuplement
            v-for="(room, indRSup) in hotel.rooms_by_price_suplement"
            :key="indRSup"
            :room="room"
            :pos="indRSup"
            :hotel="hotel"
            :edad="edadHotelOnlyAdult"
            :pos-hotel="pos"
            :all-rooms="allRooms"
            :hotel-rooms="roomsId"
            :all-suplement="allSuplement"
            :all-reductions="allReductions"
            :all-planes="allPlanes"
          />
        </fragment>
      </v-col>

      <v-col
        v-if="showRoomsPrice && ofertEBBDates.modelo === 'descuento'"
        cols="12"
        md="12"
      >
        <OfertsHotelDescuentos
          v-for="(descuento, indRDesc) in hotel.descuentos"
          :key="indRDesc"
          :descuento="descuento"
          :pos="indRDesc"
          :hotel="hotel"
          :pos-hotel="pos"
          :all-rooms="allRooms"
          :hotel-rooms="roomsId"
          :all-planes="allPlanes"
        />
      </v-col>
    </v-row>

    <v-divider class="mb-2 mt-2"></v-divider>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiArrowRightCircle,
  mdiArrowDownCircle,
} from '@mdi/js'
import OfertsHotelPrices from './OfertsHotelPrices.vue'
import OfertsHotelDescuentos from './OfertsHotelDescuentos.vue'
import OfertsHotelPricesBySuplement from './OfertsHotelPricesBySuplement.vue'

export default {
  components: {
    OfertsHotelPrices,
    OfertsHotelDescuentos,
    OfertsHotelPricesBySuplement,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReductions: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allHotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlanes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotelsContrate: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiArrowRightCircle,
        mdiArrowDownCircle,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      itemsRooms: [],
      calculators: [],
      menuFromViaje: false,
      menuToViaje: false,
      menuFromReserva: false,
      menuToReserva: false,
      menuRooming: false,
      menuFromPagar: false,
      menuFecha: false,
      roomsId: [],
      itemsHotel: [],
      searchHotel: null,
      edadHotelOnlyAdult: 0,
    }
  },
  computed: {
    ...mapState({
      ofertEBBDates: state => state.app.ofertEBBDates,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
      indexHotelEBB: state => state.app.indexHotelEBB,
      showHotelEBB: state => state.app.showHotelEBB,
    }),
    hotelsFree() {
      const result = []
      this.hotelsContrate.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.ofertEBBDates.hotels.length; index++) {
          if (this.ofertEBBDates.hotels[index].hotel_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        }
      })

      return result
    },
    showRoomsPrice() {
      if (this.roomsId.length > 0) {
        return true
      }

      return false
    },
  },
  watch: {
    searchHotel(val) {
      if (val !== null && val.length > 0) {
        this.filterHotel(val.toLowerCase())
      }
    },
  },
  created() {
    if (this.hotel.hotel_id) {
      if (this.allHotels.filter(e => e.id === this.hotel.hotel_id).length > 0) {
        this.roomsId = this.allHotels.filter(e => e.id === this.hotel.hotel_id)[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteHotelOfertEBB',
      'addHotelOfertEBB',
      'initRoomByPriceHotelOfertEBB',
      'initDescuentosOfertEBB',
      'updateShowHotelEBB',
      'updateOnlyAdult',
      'updateAllInclude',
    ]),
    setHotel() {
      if (this.allHotels.filter(e => e.id === this.hotel.hotel_id).length > 0) {
        this.roomsId = this.allHotels.filter(e => e.id === this.hotel.hotel_id)[0].rooms_id
        if (this.ofertEBBDates.modelo === 'precio') {
          this.initRoomByPriceHotelOfertEBB(this.pos)
        } else if (this.ofertEBBDates.modelo === 'descuento') {
          this.initDescuentosOfertEBB(this.pos)
        }

        const hotel = this.allHotels.filter(e => e.id === this.hotel.hotel_id)[0]
        if (hotel.only_adult === 1) {
          if (hotel.edad_min) {
            if (hotel.edad_min.edad) {
              this.edadHotelOnlyAdult = hotel.edad_min.edad
            }
          }
          this.updateOnlyAdult(true)
        } else {
          this.updateOnlyAdult(false)
        }
        if (hotel.all_include === 1) {
          this.updateAllInclude(true)
        } else {
          this.updateAllInclude(false)
        }
      }
    },
    filterHotel(v) {
      this.itemsHotel = []
      if (v === '') {
        this.itemsHotel = []
      } else {
        this.itemsHotel = this.hotelsFree.filter(e => e.name.toLowerCase())
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>
