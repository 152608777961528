<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <tbody>
          <fragment>
            <tr>
              <th class="text-uppercase">
                <b>{{ $t('lbl.identificador') }}</b>
              </th>
              <th class="text-uppercase">
                <b>{{ $t('lbl.proveedor') }}</b>
              </th>
              <th class="text-uppercase">
                <b>{{ $t('lbl.tipo') }}</b>
              </th>
              <th class="text-uppercase">
                <b>{{ $t('lbl.dateSale') }}</b>
              </th>
              <th class="text-uppercase">
                <b>{{ $t('lbl.state') }}</b>
              </th>

              <th
                v-if="
                  permisos.includes('oferts:edit') ||
                    permisos.includes('oferts:destroy') ||
                    permisos.includes('oferts:list') ||
                    permisos.includes('*')
                "
                width="150px"
                class="text-uppercase"
              >
                <b>{{ $t('lbl.actions') }}</b>
              </th>
            </tr>

            <tr
              v-for="(mark, indM) in itemsProduct"
              :key="indM"
            >
              <td>
                {{ mark.identificador }}
              </td>
              <td>
                {{ mark.proveedor }}
              </td>
              <td>
                {{ mark.tipo }}
              </td>
              <td>
                {{ $moment(mark.from_sale).format('D-MMM-YY') }} / {{ $moment(mark.to_sale).format('D-MMM-YY') }}<br />
              </td>
              <td>
                {{ mark.status }}
              </td>
              <td
                v-if="
                  permisos.includes('oferts:edit') ||
                    permisos.includes('oferts:destroy') ||
                    permisos.includes('oferts:list') ||
                    permisos.includes('*')
                "
              >
                <v-tooltip
                  v-if="permisos.includes('oferts:edit') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="editItem(mark)"
                    >
                      <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.edit') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('oferts:list') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="seeItem(mark)"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.see') }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="permisos.includes('oferts:destroy') || permisos.includes('*')"
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteItem(mark)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </fragment>
        </tbody>
      </template>
    </v-simple-table>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteOfertEBB') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEyeCheck,
  mdiEye,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    proveedoresHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    contratesHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotels: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,

      showRowProduct: false,
      showRowProveedor: false,
      showRowContrate: false,
      showRowHotel: false,
      showRowRoom: false,
      showRowTemporada: false,
      showRowDate: false,

      // itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEyeCheck,
        mdiEye,
      },
      isDialogVisible: false,
      model: {},
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      loading: false,

      showRow: false,
      indexRow: -1,

      proveedores: [],
      aerolineas: [],

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      ofertEBBList: state => state.app.ofertEBBList,
    }),
    itemsProduct() {
      if (this.ofertEBBList.length > 0) {
        const newArr = []
        this.ofertEBBList.forEach(element => {
          if (element.products === 'hotels') {
            newArr.push(element)
          }
        })

        return newArr
      }

      return []
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 100)
  },
  methods: {
    ...mapMutations(['setOnlyShow', 'emptyOfertEBB']),
    editItem(mark) {
      this.setOnlyShow(false)
      this.$emit('asign:markups', mark.id)
      window.scrollTo(0, 0)
    },
    seeItem(mark) {
      this.setOnlyShow(true)
      this.$emit('asign:markups', mark.id)
      window.scrollTo(0, 0)
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.loading = true
      this.axios
        .delete(`oferts-ebb/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            }
          } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
            this.$emit('load')
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => this.reseat())
    },
    reseat() {
      this.loading = false
      this.isDialogVisibleDelete = false
      this.emptyOfertEBB()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
