<template>
  <div v-if="!isLoading">
    <v-card-text v-if="isShow">
      <v-row v-if="!$store.state.app.onlyShow">
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                :disabled="!ofertEBB.products"
                class="mb-4 me-3"
                @click="clearItem()"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="permisos.includes('oferts:edit') || permisos.includes('*')"
                :disabled="!ofertEBB.products || !ofertEBBDates.type_offert"
                color="primary"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="4"
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.products"
                :disabled="$store.state.app.onlyShow"
                :items="products"
                :label="$t('lbl.product')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="slug"
                @change="setProduct"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.product') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showProveedor && proveedores.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.proveedor_id"
                :disabled="$store.state.app.onlyShow"
                :items="proveedores"
                :label="$t('lbl.proveedor')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                @change="setProveedor"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.proveedor') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showContrate && contrates.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.contrate_id"
                :disabled="$store.state.app.onlyShow"
                :items="contrates"
                :label="$t('lbl.contrato')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                @change="setContrate"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.contrato') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--HISTORIAL-->
            <v-col
              v-if="showContrate && contrates.length > 0"
              cols="12"
              md="12"
            >
              <span
                v-for="(histo, indHist) in ofertEBBDates.historial"
                :key="indHist"
              >
                <b>{{ histo.action }}</b>: {{ $moment(histo.date).format('D-MMM-YY') }}, {{ histo.nameUser }}<br />
              </span>
            </v-col>
            <!--CARS--
            <v-col
              v-if="categoryContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.category_id"
                :items="categoryContrate"
                :label="$t('lbl.cat')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.cat') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="marcasContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.marca_modelo_id"
                :items="marcasContrate"
                :label="$t('lbl.brandModel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.brandModel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>-->
            <!--FLIGHTS--
            <v-col
              v-if="flightContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.flight_id"
                :items="flightContrate"
                :label="$t('lbl.vuelo')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.vuelo') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>-->
            <!--HOTELS--
            <v-col
              v-if="hotelesContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.hotel_id"
                :items="hotelesContrate"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setHotel"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="roomsContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="ofertEBB.room_id"
                :items="roomsContrate"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>-->
          </v-row>
        </v-col>
        <v-col
          v-if="showOfertEBBDates"
          cols="12"
          md="7"
          offset-md="1"
        >
          <OfertsHotel
            :all-suplement="allSuplement"
            :all-reductions="allReduccions"
            :all-rooms="allRooms"
            :all-planes="allPlanes"
            :only-adult="false"
            :all-include="false"
            :user="user"
            :id-ofert-e-b-b="!ofertEBB.id ? 0 : ofertEBB.id"
          />
        </v-col>
      </v-row>

      <OfertsHotelByOne
        v-for="(hotel, indH) in ofertEBBDates.hotels"
        :key="indH + 1"
        :hotel="hotel"
        :pos="indH"
        :all-suplement="allSuplement"
        :all-reductions="allReduccions"
        :all-hotels="hotelesList"
        :all-planes="allPlanes"
        :hotels-contrate="hotelesContrate"
        :all-rooms="allRooms"
      />
    </v-card-text>

    <v-tabs v-model="currentTab">
      <!--<v-tab v-if="productsPermit.includes('vuelos')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiAirplane }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.flights') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('cars')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiCar }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.cars') }}</span>
        </v-tooltip>
      </v-tab> -->
      <v-tab v-if="productsPermit.includes('hotels')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.hotels') }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="currentTab">
      <!--<v-tab-item v-if="productsPermit.includes('vuelos')">
        <FlightsTab
          v-if="showTabs"
          :proveedores="proveedoresFlight"
          :contrates="contratesFlight"
          :flights="flightsList"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('cars')">
        <CarsTab
          v-if="showTabs"
          :proveedores-car="proveedoresCar"
          :contrates-car="contratesCar"
          :categories-car="categoriesCar"
          :marcas-car="marcasCar"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>-->
      <v-tab-item v-if="productsPermit.includes('hotels')">
        <HotelsTab
          v-if="showTabs"
          :proveedores-hotel="proveedoresHotel"
          :contrates-hotel="contratesHotel"
          :hotels="hotelesList"
          :rooms="roomsList"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
    </v-tabs-items>

    <!-- MOSTRAR CHANGES -->
    <v-dialog
      v-model="changesNotUpdate"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.update') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('lbl.notChangesUpdate') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="changesNotUpdate = !changesNotUpdate"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="continueClear()"
          >
            {{ $t('btn.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiAirplane,
  mdiCar,
  mdiOfficeBuilding,
  mdiTrashCan,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
// import FlightTab from '../tabs/Flights.vue'

// import CarsTab from '../tabs/Cars.vue'
// import FlightsTab from '../tabs/Flights.vue'
import HotelsTab from '../tabs/Hotels.vue'

import OfertsHotel from '../utils/OfertsHotel.vue'
import OfertsHotelByOne from '../utils/OfertsHotelByOne.vue'

export default {
  components: {
    OfertsHotel,
    OfertsHotelByOne,
    HotelsTab,

    // FlightsTab,
    // CarsTab,
  },
  data() {
    return {
      isLoading: true,
      loading: false,
      isShow: true,
      currentTab: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiAirplane,
        mdiCar,
        mdiOfficeBuilding,
        mdiTrashCan,
      },
      itemsPerPage: 10,
      permisos: sessionStorage.getItem('permisos_auth'),
      ofertEBB: {},
      products: [],
      proveedoresHotel: [],
      proveedoresCar: [],
      proveedoresFlight: [],
      proveedores: [],
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
      contrates: [],
      contratesHotel: [],
      hotelesList: [],
      hotelesContrate: [],
      roomsList: [],
      roomsContrate: [],
      flightsList: [],
      contratesCar: [],
      categoriesCar: [],
      marcasCar: [],
      contratesFlight: [],
      categoryContrate: [],
      marcasContrate: [],
      flightContrate: [],
      typeAfiliados: [],
      showTabs: true,
      items: [],
      user: {},
      productsPermit: [],
      allSuplement: [],
      allRooms: [],
      allReduccions: [],
      allPlanes: [],
      changesNotUpdate: false,
    }
  },
  computed: {
    ...mapState({
      ofertEBBDates: state => state.app.ofertEBBDates,
      ofertEBBDatesOrigin: state => state.app.ofertEBBDatesOrigin,
    }),
    computedFromDateFormatted() {
      return this.ofertEBB.from ? this.$moment(this.ofertEBB.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.ofertEBB.to ? this.$moment(this.ofertEBB.to).format('D-MMM-YY') : ''
    },

    showProveedor() {
      if (this.ofertEBB.products) {
        return true
      }

      return false
    },
    showDateFrom() {
      if (this.ofertEBB.products.length > 0) {
        return true
      }

      return false
    },
    showContrate() {
      if (this.ofertEBB.proveedor_id) {
        return true
      }

      return false
    },
    showOfertEBBDates() {
      if (this.ofertEBB.products === 'hotels' && this.ofertEBB.proveedor_id && this.ofertEBB.contrate_id) {
        if (!this.ofertEBB.id) {
          this.initOfertEBB()
          this.asigUserHistorialOfertEBB({ nameUser: `${this.user.name} ${this.user.apellidos}`, idUser: this.user.id })
        }

        return true
      }

      return false
    },
  },
  mounted() {
    this.profile()
  },
  created() {
    this.resetItems()

    this.load()

    this.getProveedoresHotel()
    this.getProveedoresCar()
    this.getProveedoresFlights()

    this.getContratesHotel()
    this.getContratesCar()
    this.getContratesFlight()
    this.getCategoriesCar()
    this.getMarcasCar()

    this.getHoteles()
    this.getRooms()
    this.getPlanes()
    this.getFlights()

    this.getSuplements()
    this.getReduccions()
  },
  methods: {
    ...mapMutations([
      'emptyOfertEBB',
      'initOfertEBB',
      'asigUserHistorialOfertEBB',
      'updateOfertEBBList',
      'deleteStopSalesList',
      'addDateStopSale',
      'deleteDateStopSale',
      'updateOfertEBBDates',
      'updateOfertEBBDatesOrigin',
    ]),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getProductsPermit()

          // this.isLoading = false
        })
    },
    load() {
      this.resetItems()
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('oferts-ebb/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.updateOfertEBBList(this.items)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          /* this.products.push({
            slug: 'all',
            name: 'Todos',
          }) */
          const rrr = res.data.data

          this.productsPermit.forEach(elementPermit => {
            rrr.forEach(element => {
              if (elementPermit === element.entity && element.entity === 'hotels') {
                this.products.push({
                  slug: element.entity,
                  name: element.name,
                })
              } /* else if (elementPermit === 'vuelos' && element.entity === 'flights') {
                this.products.push({
                  slug: element.entity,
                  name: element.name,
                })
              } */
            })
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getProductsPermit() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.productsPermit.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.productsPermit.push(element.slug)
            })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getProducts()
        })
    },
    getProveedoresHotel() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresHotel = res.data.data
        })
    },
    getProveedoresCar() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresCar = res.data.data
        })
    },
    getProveedoresFlights() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresFlight = res.data.data
        })
    },
    getContratesHotel() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesHotel = res.data.data
          }
        })
    },
    getContratesCar() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesCar = res.data.data
          }
        })
    },
    getContratesFlight() {
      const json = {
        per_page: 10000,
        stopSale: 10,
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesFlight = res.data.data
          }
        })
    },
    getCategoriesCar() {
      this.axios
        .get('nom_categories_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCar = res.data.data
          }
        })
    },
    getMarcasCar() {
      const json = {
        per_page: 10000,
        search: { marca_id: 0 },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.marcasCar = res.data.data
          }
        })
    },
    getHoteles() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.hotelesList = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=10000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsList = res.data.data
          this.allRooms = res.data.data
        })
    },
    getPlanes() {
      this.axios
        .get('nom_regimen_alimenticio?&per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.allPlanes = res.data.data
          }
        })
    },
    getSuplements() {
      this.axios
        .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allSuplement = res.data.data
        })
    },
    getReduccions() {
      this.axios
        .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allReduccions = res.data.data
        })
    },
    getFlights() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.flightsList = res.data.data
          }
        })
    },

    setProduct() {
      if (this.ofertEBB.products) {
        // BUSCAR LOS PROVEEDORES DE LOS PRODUCTOS
        if (this.ofertEBB.products === 'hotels') {
          this.proveedoresHotel.forEach(element => {
            this.proveedores.push({
              id: element.id,
              name: element.name_comercial,
            })
          })
        } else if (this.ofertEBB.products === 'cars') {
          this.proveedoresCar.forEach(element => {
            this.proveedores.push({
              id: element.id,
              name: element.name_comercial,
            })
          })
        } else if (this.ofertEBB.products === 'flights') {
          this.proveedoresFlight.forEach(element => {
            this.proveedores.push({
              id: element.id,
              name: element.name_comercial,
            })
          })
        }

        // AUTOSELECT
        if (!this.ofertEBB.id && this.proveedores.length === 1) {
          this.ofertEBB.proveedor_id = this.proveedores[0].id
          this.setProveedor()
        }
      } else {
        this.resetProducts()
      }

      /* this.ofertEBB.from = this.$moment(new Date())
          .toDate()
          .toISOString()
          .substr(0, 10) */
    },
    resetProducts() {
      this.proveedores = []
      this.ofertEBB.proveedor_id = null
      this.contrates = []
      this.ofertEBB.contrate_id = null
      this.categoryContrate = []
      this.ofertEBB.category_id = null
      this.marcasContrate = []
      this.ofertEBB.marca_modelo_id = null
      this.hotelesContrate = []
      this.ofertEBB.hotel_id = null
      this.roomsContrate = []
      this.ofertEBB.room_id = null
      this.flightContrate = []
      this.ofertEBB.flight_id = null
    },
    setProveedor() {
      if (this.ofertEBB.proveedor_id) {
        /* if (!this.ofertEBB.id) {
          this.ofertEBB.contrate_id = [this.contrates[0].id]
        } */

        // BUSCAR LOS CONTRATOS DE LOS PRODUCTOS
        if (this.ofertEBB.products === 'hotels') {
          this.contrates = []
          this.hotelesContrate = []
          this.contratesHotel.forEach(element => {
            if (this.ofertEBB.proveedor_id === element.proveedor_id) {
              this.contrates.push({
                id: element.id,
                name: element.identificador,
              })
            }
          })
        } else if (this.ofertEBB.products === 'cars') {
          this.contratesCar.forEach(element => {
            if (this.ofertEBB.proveedor_id === element.proveedor_id) {
              this.contrates.push({
                id: element.id,
                name: element.identificador,
              })
            }
          })
        } else if (this.ofertEBB.products === 'flights') {
          this.contratesFlight.forEach(element => {
            if (this.ofertEBB.proveedor_id === element.proveedor_id) {
              this.contrates.push({
                id: element.id,
                name: element.identificador,
              })
            }
          })
        }

        // AUTOSELECT
        if (!this.ofertEBB.id && this.contrates.length === 1) {
          this.ofertEBB.contrate_id = this.contrates[0].id
          this.setContrate()
        }
      } else {
        this.resetProveedor()
      }
    },
    resetProveedor() {
      this.contrates = []
      this.ofertEBB.contrate_id = null
      this.categoryContrate = []
      this.ofertEBB.category_id = null
      this.marcasContrate = []
      this.ofertEBB.marca_modelo_id = null
      this.hotelesContrate = []
      this.ofertEBB.hotel_id = null
      this.roomsContrate = []
      this.ofertEBB.room_id = null
      this.flightContrate = []
      this.ofertEBB.flight_id = null
    },
    setContrate() {
      if (this.ofertEBB.contrate_id) {
        /* if (this.ofertEBB.products === 'cars') {
          const contrateSelect = this.contratesCar.filter(e => e.id === this.ofertEBB.contrate_id)
          if (contrateSelect.length > 0) {
            this.temporadas.push({
              id: 'all',
              name: 'Todas',
              dates: [],
            })

            if (contrateSelect[0].temporadas.length > 0) {
              contrateSelect[0].temporadas.forEach(seas => {
                this.temporadas.push({
                  id: seas.temporada_id,
                  name: seas.name,
                  dates: seas.dates,
                })
              })

              // AUTOSELECT
              if (this.temporadas.length === 1) {
                this.temporadas.splice(0, 1)
              } else if (this.temporadas.length === 2) {
                this.temporadas.splice(0, 1)
                if (!this.ofertEBB.id) {
                  this.ofertEBB.temporada_id = this.temporadas[0].id
                }
              }
            }
            if (contrateSelect[0].tipo_contrato === 'categoria') {
              this.categoryContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].categories) {
                contrateSelect[0].categories.forEach(cate => {
                  this.categoryContrate.push({
                    id: cate.category_id.id,
                    name: cate.category_id.name,
                  })
                })
              }

              // AUTOSELECT
              if (this.categoryContrate.length === 1) {
                this.categoryContrate.splice(0, 1)
              } else if (this.categoryContrate.length === 2) {
                this.categoryContrate.splice(0, 1)
                if (!this.ofertEBB.id) {
                  this.ofertEBB.category_id = this.categoryContrate[0].id
                }
              }
            } else {
              this.marcasContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].marcas) {
                contrateSelect[0].marcas.forEach(mar => {
                  mar.cars_id.forEach(car => {
                    this.marcasContrate.push({
                      id: car.id,
                      name: car.name,
                    })
                  })
                })
              }

              // AUTOSELECT
              if (this.marcasContrate.length === 1) {
                this.marcasContrate.splice(0, 1)
              } else if (this.marcasContrate.length === 2) {
                this.marcasContrate.splice(0, 1)
                if (!this.ofertEBB.id) {
                  this.ofertEBB.marca_modelo_id = this.marcasContrate[0].id
                }
              }
            }
          }
        } else */ if (
          this.ofertEBB.products.includes('hotels')
        ) {
          const contrateSelect = this.contratesHotel.filter(e => e.id === this.ofertEBB.contrate_id)
          if (contrateSelect.length > 0) {
            const hotelesPactados = contrateSelect[0].hotels_pactados

            hotelesPactados.forEach(hote => {
              const ppp = this.hotelesList.filter(e => e.id === hote)
              if (ppp.length > 0) {
                this.hotelesContrate.push({
                  id: ppp[0].id,
                  name: ppp[0].name,
                })
              }
            })
          }
        } /* else if (this.ofertEBB.products.includes('flights')) {
          const contrateSelect = this.contratesFlight.filter(e => e.id === this.ofertEBB.contrate_id)
          if (contrateSelect.length > 0) {
            this.flightContrate.push({
              id: -1,
              name: 'Todos',
            })
            const flightId = contrateSelect[0].flight
            if (flightId !== null) {
              this.flightContrate.push({
                id: flightId.id,
                name: flightId.numero_de_vuelo,
              })
            }

            // AUTOSELECT
            if (this.flightContrate.length === 1) {
              this.flightContrate.splice(0, 1)
            } else if (this.flightContrate.length === 2) {
              this.flightContrate.splice(0, 1)
              if (!this.ofertEBB.id) {
                this.ofertEBB.flight_id = this.flightContrate[0].id
              }
            }
          }
        } */
      } else {
        this.resetContrate()
      }
    },
    resetContrate() {
      this.categoryContrate = []
      this.ofertEBB.category_id = null
      this.marcasContrate = []
      this.ofertEBB.marca_modelo_id = null
      this.hotelesContrate = []
      this.ofertEBB.hotel_id = null
      this.roomsContrate = []
      this.ofertEBB.room_id = null
      this.flightContrate = []
      this.ofertEBB.flight_id = null
    },

    asignMarkups(id) {
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []

      this.isShow = false

      const mmm = this.items.filter(e => e.id === id)
      if (mmm.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.ofertEBB = {
          id: mmm[0].id,
          products: mmm[0].products,
          proveedor_id: mmm[0].proveedor_id,
          contrate_id: mmm[0].contrate_id,

          // CARS
          category_id: mmm[0].category_id,
          marca_modelo_id: mmm[0].marca_modelo_id,

          // HOTELS
          hotel_id: mmm[0].hotel_id,
          room_id: mmm[0].room_id,

          // FLIGHTS
          aeroline_id: mmm[0].aeroline_id,
          flight_id: mmm[0].flight_id,
        }

        this.updateOfertEBBDates(mmm[0].data)

        this.axios
          .get(`oferts-ebb/${mmm[0].id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              // console.log(res.data.data)
              this.updateOfertEBBDatesOrigin(res.data.data.data)
            }
          })

        //
        setTimeout(() => {
          this.isShow = true
        }, 100)
      }

      this.setProduct()
      this.setProveedor()
      this.setContrate()
    },
    save() {
      if (this.ofertEBB.products && this.ofertEBB.proveedor_id && this.ofertEBB.contrate_id) {
        let procesar = true
        if (this.ofertEBB.products === 'hotels') {
          if (
            !this.ofertEBBDates.identificador
            || !this.ofertEBBDates.type_offert
            || !this.ofertEBBDates.fromReserva
            || !this.ofertEBBDates.toReserva
          ) {
            procesar = false
          } else {
            this.ofertEBBDates.hotels.forEach(hot => {
              if (!hot.hotel_id) {
                procesar = false
                // eslint-disable-next-line no-undef
              } else if (this.ofertEBBDates.modelo === 'precio') {
                // eslint-disable-next-line no-undef
                if (!this.ofertEBBDates.by_suplement_reductions) {
                  // eslint-disable-next-line camelcase
                  hot.rooms_by_price.forEach(room_price => {
                    if (!room_price.room_id || !room_price.fromViaje || !room_price.toViaje || !room_price.plan_id) {
                      procesar = false
                    } else if (!room_price.price_doble && !room_price.beneficios) {
                      procesar = false
                    }
                  })
                } else {
                  // eslint-disable-next-line camelcase
                  hot.rooms_by_price_suplement.forEach(room_price => {
                    if (!room_price.room_id || !room_price.fromViaje || !room_price.toViaje || !room_price.plan_id) {
                      procesar = false
                    } else if (!room_price.price_doble && !room_price.beneficios) {
                      procesar = false
                    }
                  })
                }
              } else if (this.ofertEBBDates.modelo === 'descuento') {
                // eslint-disable-next-line camelcase
                hot.descuentos.forEach(room_price => {
                  if (
                    !room_price.rooms_id.length === 0
                    || !room_price.fromViaje
                    || !room_price.toViaje
                    || !room_price.plan_id
                    || !room_price.valueDescuento
                  ) {
                    procesar = false
                  }
                })
              }
            })
          }
        }
        if (procesar) {
          const json = {
            ...this.ofertEBB,
            data: this.ofertEBBDates,
          }
          this.loading = true
          if (this.ofertEBB.id === null) {
            this.axios
              .post('oferts-ebb', json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    // this.$toast.error(this.$t('msg.noAutorice'))
                  }
                } else {
                  this.load()
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => {
                this.resetItems()
                this.emptyOfertEBB()
              })
          } else {
            this.axios
              .put(`oferts-ebb/${this.ofertEBB.id}`, json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                if (response.data.success === false) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else {
                  this.load()
                  window.scrollTo(0, 10000)
                  this.$toast.success(this.$t('msg.infoSuccess'))
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => {
                this.resetItems()
                this.emptyOfertEBB()
              })
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearItem() {
      // DETECTAR SI HUBO CAMBIOS
      const jsonOfertEBBDates = JSON.stringify(this.ofertEBBDates)
      const jsonOfertEBBDatesOrigin = JSON.stringify(this.ofertEBBDatesOrigin.data)
      if (jsonOfertEBBDates !== jsonOfertEBBDatesOrigin) {
        this.changesNotUpdate = true
      } else {
        this.continueClear()
      }

      /* if (
        this.ofertEBBDates.identificador !== this.ofertEBBDatesOrigin.data.identificador
        || this.ofertEBBDates.type_offert !== this.ofertEBBDatesOrigin.data.type_offert
        || this.ofertEBBDates.fromReserva !== this.ofertEBBDatesOrigin.data.fromReserva
        || this.ofertEBBDates.toReserva !== this.ofertEBBDatesOrigin.data.toReserva
        || this.ofertEBBDates.rooming !== this.ofertEBBDatesOrigin.data.rooming
        || this.ofertEBBDates.datePublic !== this.ofertEBBDatesOrigin.data.datePublic
        || this.ofertEBBDates.dateUpdate !== this.ofertEBBDatesOrigin.data.dateUpdate
        || this.ofertEBBDates.dateUpdateOld !== this.ofertEBBDatesOrigin.data.dateUpdateOld
        || this.ofertEBBDates.dateUpdateSaved !== this.ofertEBBDatesOrigin.data.dateUpdateSaved
        || this.ofertEBBDates.dateCancele !== this.ofertEBBDatesOrigin.data.dateCancele
        || this.ofertEBBDates.stopSale !== this.ofertEBBDatesOrigin.data.stopSale
        || this.ofertEBBDates.dateStopSale !== this.ofertEBBDatesOrigin.data.dateStopSale
        || this.ofertEBBDates.modelo !== this.ofertEBBDatesOrigin.data.modelo
        || this.ofertEBBDates.calculadoPor !== this.ofertEBBDatesOrigin.data.calculadoPor
        || this.ofertEBBDates.by_suplement_reductions !== this.ofertEBBDatesOrigin.data.by_suplement_reductions
        || this.ofertEBBDates.price_by_room !== this.ofertEBBDatesOrigin.data.price_by_room
      ) {
        this.changesNotUpdate = true
      } else if (this.ofertEBBDates.pays.length !== this.ofertEBBDatesOrigin.data.pays.length) {
        this.changesNotUpdate = true
      } else if (this.ofertEBBDates.hotels.length !== this.ofertEBBDatesOrigin.data.hotels.length) {
        this.changesNotUpdate = true
      } else {
        this.continueClear()
      } */

      // this.resetItems()
      // this.emptyOfertEBB()
    },
    continueClear() {
      this.changesNotUpdate = false
      this.resetItems()
      this.emptyOfertEBB()
    },

    resetItems() {
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []
      this.flightContrate = []
      this.ofertEBB = {
        id: null,
        products: null,
        proveedor_id: null,
        contrate_id: null,

        // CARS
        category_id: null,
        marca_modelo_id: null,

        // HOTELS
        hotel_id: null,
        room_id: null,

        // FLIGHTS
        aeroline_id: null,
        flight_id: null,
      }

      this.loading = false
      this.showTabs = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
